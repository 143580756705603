
/* body {
  background-color:'#ec2f2f';
} */

.divider {
  height: 150px;
}
.h-row-big {
  display: block;
}
.h-row-small {
  display: none;
}

@media (max-width: 991.98px) {
  .divider {
      height: 200px;
  }
}

@media (max-width: 700px) {
  .h-row-big {
    display: none;
  }

  .h-row-small {
    display: block;
  }
}


.pricing-header {
  max-width: 700px;
}



.card{
  /* background-color:#fff2e0;
   z-index:10;
  font-family: 'Poppins', sans-serif; */
  padding:20px;
  border-radius:20px;
  
}



/* .loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(104, 31, 240, 0.834)
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
  z-index: 1;
} */

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  
  /* background: #ec2f2f; */

  background: url('../public/assets/images/ghana12.jpg') #52fa4d;
  background-size: cover;
  background-blend-mode: multiply;

  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #ec2f2f transparent #f5f245 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}



.d-color-box {
  border: 1px solid #ec2f2f;
  width: 80px;
  /* height: 34px; */
  display: inline-block; /* Ensure the span takes only the width of its contents */
}

.text {
  margin-left: 5px;
}

@keyframes changeBorderColor {
  0% {
    border-color: #ec2f2f;
    color: #00FF00;
  }
  33% {
    border-color: #00FF00; /* Change this to the desired color */
    color: #ec2f2f; /* Change this to the desired color */
  }
  66% {
    border-color: #FF0000; /* Change this to the desired color */
    color: #00FF00; /* Change this to the desired color */
  }
  100% {
    border-color: #ec2f2f;
    color: #FF0000; 
  }
}

.border-blue {
  animation: changeBorderColor 3s infinite;
}

span, a {
  cursor: pointer;
}