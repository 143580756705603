.b-screen {
    display: block;
}
.m-screen {
    display: none;
}

@media (max-width: 991.98px) {
    .b-screen {
      display: none;
    }
    .m-screen {
        display: block;
    }
}